import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rightfirstoverlay',
  templateUrl: './rightfirstoverlay.component.html',
  styleUrls: ['./rightfirstoverlay.component.css']
})
export class RightfirstoverlayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  closeNavRightFirst() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      document.getElementById("rightfirstoverlayimage").style.width = "0%";
      document.getElementById("rightfirstoverlayimage").style.height = "0px";
    }else{
      document.getElementById("rightfirstoverlayimage").style.width = "0%";
      document.getElementById("rightfirstoverlayimage").style.height = "100vh";
    }
    document.getElementById("overlaytextRightFirst").style.display = "none";    
    document.getElementById("myNavRightFirst").style.width = "0%";
  }

}
