import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sendemail',
  templateUrl: './sendemail.component.html',
  styleUrls: ['./sendemail.component.css']
})
export class SendemailComponent implements OnInit {

  showRadioContent = false;
  constructor() { }
  ngOnInit(): void {
  }
  submitForm() {
    var name = (<HTMLInputElement>document.getElementById('name')).value;
    var number = (<HTMLInputElement>document.getElementById('number')).value;

    if (this.showRadioContent && (name.length>0) && (number.length>0)) {
      document.getElementById("failresult").style.display="none";
      document.getElementById("succesresult").style.display="block";  
    } else {
      document.getElementById("succesresult").style.display="none";
      document.getElementById("failresult").style.display="block";  
    }
  }
}
