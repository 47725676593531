import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-rightsecondoverlay',
  templateUrl: './rightsecondoverlay.component.html',
  styleUrls: ['./rightsecondoverlay.component.css']
})
export class RightsecondoverlayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  closeNavRightSecond() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      document.getElementById("rightsecondoverlayimage").style.width = "0%";
      document.getElementById("rightsecondoverlayimage").style.height = "0px";
    }else{
      document.getElementById("rightsecondoverlayimage").style.width = "0%";
      document.getElementById("rightsecondoverlayimage").style.height = "100vh";
    }
    document.getElementById("overlaytextRightSecond").style.display = "none";    
    document.getElementById("myNavRightSecond").style.width = "0%";
  }

}
