<div class="blackline">
  <div class="containerimgback">
    <img src="/assets/img/containerbackslider.png" class="opacity"/>
  </div>
</div>
<div class="container">
  <div class="carousel"
  data-flickity='{ "imagesLoaded": true, 
  "percentPosition": false, 
  "wrapAround": true
   }'>
  <!-- <img src="/assets/img/slide1.png" alt="" />
  <img src="/assets/img/slide2.png" alt="" />
  <img src="/assets/img/slide3.png" alt="" /> -->
  
  <img src="/assets/img/slide6.jpg" alt="" />
  <img src="/assets/img/slide7.jpg" alt="" />
  <img src="/assets/img/slide8.jpg" alt="" />
  <img src="/assets/img/slide4.jpg" alt="" />
  <img src="/assets/img/slide5.jpg" alt="" />
</div>

</div>
