<div id="footer" class="footer">
    <div class="imagecont">
        <img src="/assets/img/fcontainer.png" class="transparent25">
    </div>
    <div class="row">
        <div class="side">
            <div class="name">
                <p>BCTS Depot (Bimicon Container Service GmbH)</p>
                <p style="font-size:60%;">BCTS Depot Adresse: Am Radeland 5C, 21079 Hamburg</p>
                <p style="font-size:60%;">Öffnungszeiten von 7:00 bis 17:00</p>
            </div>
            <div class="bottomline">
                <p>Designed in CloudMill</p>
                <p>Developed by SOYSystems</p>
                <p>© 1996–2020, BCTS</p>
            </div>
        </div>
        <div class="side">
            <div class="phone">
                <p>+49 40 3346853130</p>
            </div>
            <div class="email">
                <p>info@bctsdepot.de</p>
            </div>
            <div class="bottomline">
                <div class="leftpartbottom">
                    <p><a mat-button (click)="openDialogDatePolicy()">Datenschutz</a></p>
                    <p><a mat-button (click)="openDialogImpressum()">Impressum</a></p>
                    <p><a mat-button (click)="openDialogAGB()">AGB</a></p>
                </div>
                <div class="rightpartbottom">
                    <p>Offizieller Lieferant</p>
                    <!-- <br> -->
                    <p><a href="https://bimicon.de/" target="_blank">bimicon.de</a></p>
                </div>
            </div>
        </div>

    </div>


</div>