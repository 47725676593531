import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  openNav() {
    document.getElementById("myNav").style.width = "100%";
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      document.getElementById("overlayimage").style.width = "100%";
      document.getElementById("overlayimage").style.height = "250px";
      document.getElementById("overlayimage").style.display = "block";
      document.getElementById("overlaytext").style.display = "block";
    }else{
      document.getElementById("overlayimage").style.width = "35%";
      document.getElementById("overlayimage").style.height = "100vh";
      document.getElementById("overlayimage").style.display = "inline-block";
      setTimeout(() => {  document.getElementById("overlaytext").style.display = "inline-block"; }, 1500);
      
    }
    
    
  }

  openNav2() {
    document.getElementById("myNav2").style.width = "100%";
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      document.getElementById("overlayimage2").style.width = "100%";
      document.getElementById("overlayimage2").style.height = "250px";
      document.getElementById("overlayimage2").style.display = "block";
      document.getElementById("overlaytext2").style.display = "block";
      console.log("it's mobile!");
    }else{
      document.getElementById("overlayimage2").style.width = "35%";
      document.getElementById("overlayimage2").style.height = "100vh";
      document.getElementById("overlayimage2").style.display = "inline-block";
      setTimeout(() => {  document.getElementById("overlaytext2").style.display = "inline-block"; }, 1500);      
    }
  }

  openNavRightFirst() {
    document.getElementById("myNavRightFirst").style.width = "100%";
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      document.getElementById("rightfirstoverlayimage").style.width = "100%";
      document.getElementById("rightfirstoverlayimage").style.height = "250px";
      document.getElementById("rightfirstoverlayimage").style.display = "block";
      document.getElementById("rightfirstoverlayimage").style.display = "block";
      console.log("it's mobile!");
    }else{
      document.getElementById("rightfirstoverlayimage").style.width = "35%";
      document.getElementById("rightfirstoverlayimage").style.height = "100vh";
      document.getElementById("rightfirstoverlayimage").style.display = "inline-block";
      setTimeout(() => {  document.getElementById("overlaytextRightFirst").style.display = "inline-block"; }, 1500);      
    }
  }

  openNavRightSecond() {
    document.getElementById("myNavRightSecond").style.width = "100%";
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      document.getElementById("rightsecondoverlayimage").style.width = "100%";
      document.getElementById("rightsecondoverlayimage").style.height = "250px";
      document.getElementById("rightsecondoverlayimage").style.display = "block";
      document.getElementById("rightsecondoverlayimage").style.display = "block";
      console.log("it's mobile!");
    }else{
      document.getElementById("rightsecondoverlayimage").style.width = "35%";
      document.getElementById("rightsecondoverlayimage").style.height = "100vh";
      document.getElementById("rightsecondoverlayimage").style.display = "inline-block";
      setTimeout(() => {  document.getElementById("overlaytextRightSecond").style.display = "inline-block"; }, 1500);      
    }
  }
  
  closeNav() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      document.getElementById("overlayimage").style.width = "0%";
      document.getElementById("overlayimage").style.height = "0px";
    }else{
      document.getElementById("overlayimage").style.width = "0%";
      document.getElementById("overlayimage").style.height = "100vh";
    }
    
    // document.getElementById("overlayimage").style.display = "none";
    document.getElementById("overlaytext").style.display = "none";
    console.log("function called");
    document.getElementById("myNav").style.width = "0%";
    
  }
}
