<div class="sendemail">
    <h1>
        wenn Sie unsere Dienstleistungen 
        in Anspruch nehmen möchten, kontaktieren Sie uns gerne!
    </h1>
    <iframe id="iframe" name="my_iframe" hidden></iframe>
    <form
        class="gform"
        data-email="petrukhin.s.a@gmail.com"
        action="https://script.google.com/macros/s/AKfycbxcEieU-FHp_7b1slvTx7GmuWVF6wGxPvsDTg98/exec"
        method="POST"
        target="my_iframe"
        name="contact-form"
        >
        <div class="half">
            <input type="text" id="name" name="name" placeholder="Name" #name
            oninvalid="this.setCustomValidity('Schreiben Sie Namme bitte!')" onchange="this.setCustomValidity('')" required>
            <div class="square-radio square-radio--clicked desktop" (click)="showRadioContent=!showRadioContent">
                <div class="square-radio--content" *ngIf="showRadioContent">
                </div>
                <div class="radiotext">
                    <p>Ich Stimme den Bedingungen der Datenschutzrichtlinie zu</p>    
                </div>
           </div>
           
        </div>
        <div class="half">
            <input type="text" id="number" name="number" placeholder="Telefonummer"
            oninvalid="this.setCustomValidity('Schreiben Sie Telefonummer bitte!')" onchange="this.setCustomValidity('')" required>
            <br>
            <div class="square-radio square-radio--clicked mobile" (click)="showRadioContent=!showRadioContent">
                <div class="square-radio--content" *ngIf="showRadioContent">
                </div>
                <div class="radiotext">
                    <p>Ich Stimme den Bedingungen der Datenschutzrichtlinie zu</p>    
                </div>
           </div>
            <input class="sendButton" type="submit" value="SENDEN" (click)="submitForm()">
        </div>
    </form>

    <div class="result">
        <h2 class="SuccessResult" id="succesresult" >Erfolg! Sie haben Ihre Telefonummer und Name gesendet.</h2>
        <h2 class="failResult" id="failresult" >Alle Felder sind erforderlich!</h2>
    </div>
</div>
