<div class="header">
    <div>
        <div class="square" (click)="openNav()">
            <div class="plus-icon"> </div>
            <div class="squaretext">
                <h3>Einlagerung</h3>
                <h2>leerer container</h2>
            </div>

        </div>
        <div class="logo">

        </div>
        <div class="contactsbutton">
            <a href="#footer" class="w3-btn">Kontaktieren</a>
        </div>

        <div class="firstrightsquare" (click)="openNavRightFirst()">
            <div class="plus-icon"> </div>
            <div class="squaretext">
                <h2>Seefracht Weltweit</h2>
            </div>

        </div>
    </div>
    <div>
        <div class="secondsquare" (click)="openNav2()">
            <div class="secondplus-icon"></div>
            <div class="squaretext">
                <h3>Einlagerung</h3>
                <h2>voller container</h2>
            </div>
        </div>
        <div class="secondrightsquare" (click)="openNavRightSecond()">
            <div class="secondplus-icon"> </div>
            <div class="squaretext">
                <h2>Umladung von Fracht</h2>
            </div>

        </div>
    </div>
    <div class="text">
        <p>Lagerung von leeren und beladenen Containern mit begleitenden Dienstleistungen</p>
    </div>

</div>

<div id="myNav" class="overlay">
    <a href="javascript:void(0)" class="closebtn" (click)="closeNav()"></a>
    <div id="overlay-content" class="overlay-content">
        <div id="overlaytext" class="overlaytext side">
            <div class="halfheight">
                <h3>Depoleistungen</h3>
                <h1>leercontainer</h1>
            </div>
            <div class="halfheight">
                <div class="leftsidetext">
                    <div class="line">
                        <div class="leftofline">
                            <p>Gate </p>
                        </div>
                        <div class="rightofline">
                            <p>in - €35,00 / out - €35,00</p>
                        </div>
                    </div>
                    <div class="line">
                        <div class="leftofline">
                            <p>Storage</p>
                        </div>
                        <div class="rightofline">
                            <p>€ 0,80 p. TEU/Tg.</p>
                        </div>
                    </div>
                    <div class="line">
                        <div class="leftofline">
                            <p>Reinigung p. 20‘ Ctr. inkl.Handling</p>
                        </div>
                        <div class="rightofline">
                            <p>€ 65,00 </p>
                        </div>
                    </div>
                    <div class="line">
                        <div class="leftofline">
                            <p>Reinigung p. 40‘ Ctr. inkl.Handling</p>
                        </div>
                        <div class="rightofline">
                            <p>€ 85,00</p>
                        </div>
                    </div>
                    <div class="line">
                        <div class="leftofline">
                            <p>Ausfegen des Containers</p>
                        </div>
                        <div class="rightofline">
                            <p> € 25,00</p>
                        </div>
                    </div>

                </div>
                <div class="rightsidetext">
                    <div class="line">
                        <div class="leftofline">
                            <p>Inspektion Handling</p>
                        </div>
                        <div class="rightofline">
                            <p>€ 15,00</p>
                        </div>
                    </div>
                    <div class="line">
                        <div class="leftofline">
                            <p>Reparatur Arbeitstunde</p>
                        </div>
                        <div class="rightofline">
                            <p>€ 55,00 p.Std</p>
                        </div>
                    </div>
                    <div class="line">
                        <div class="leftofline">
                            <p>Foto Set(8-10 St.)</p>
                        </div>
                        <div class="rightofline">
                            <p>€ 25,00 p.Ctr.</p>
                        </div>
                    </div>
                    <div class="line">
                        <div class="leftofline">
                            <p>Kostenvoranschlag Gebühr</p>
                        </div>
                        <div class="rightofline">
                            <p>€ 15,00 p.Ctr.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div id="overlayimage" class="overlayimage side">
            <img src="/assets/img/firstpanel.jpg" class="desktopimage" />
            <img src="/assets/img/firstpanelm.jpg" class="mobileimage" />
        </div>
    </div>
</div>

<app-secondoverlay></app-secondoverlay>
<app-rightfirstoverlay></app-rightfirstoverlay>
<app-rightsecondoverlay></app-rightsecondoverlay>