import { Component, OnInit } from '@angular/core';
import { DatenschutzComponent } from 'src/app/datenschutz/datenschutz.component';
import { MatDialog } from '@angular/material/dialog';
import { ImpressumComponent } from 'src/app/impressum/impressum.component';
import { AgbcomponentComponent } from 'src/app/agbcomponent/agbcomponent.component';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent {

  constructor(public dialog: MatDialog) {}

  openDialogDatePolicy() {
    const dialogRef = this.dialog.open(DatenschutzComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openDialogImpressum() {
    const dialogRef = this.dialog.open(ImpressumComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openDialogAGB() {
    const dialogRef = this.dialog.open(AgbcomponentComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
