<div id="myNavRightSecond" class="overlay2">
    <a href="javascript:void(0)" class="closebtn" (click)="closeNavRightSecond()"></a>
    <div id="overlay-content2" class="overlay-content2">
        <div id="overlaytextRightSecond" class="overlaytext side">
            <div id="halfheight1" class="halfheight">
                <h1>Umladen von Fracht</h1>
            </div>
            <div id="halfheight2" class="halfheight">
                <div class="leftsidetext">
                    <div class="line">
                        <div class="leftofline">
                            <p>In unserem Depot wird Ihre Fracht, Palettenware oder Stückgut sicher von einem Container zum anderen umgeladen.</p>
                        </div>
                        <div class="rightofline">
                            <p></p>
                        </div>
                    </div>


                </div>


            </div>
        </div>
        <div id="rightsecondoverlayimage" class="overlayimage side">
            <img src="/assets/img/secondrightoverlay.jpg" class="desktopimage" />
            <img src="/assets/img/secondpanelm.jpg" class="mobileimage" />
        </div>
    </div>
</div>