import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'bctsdepot';
  constructor(private spinner: NgxSpinnerService) {}
 
  ngOnInit() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      this.spinner.show();
    
      setTimeout(() => {
        this.spinner.hide();
      }, 3000);
      if (!localStorage.getItem('foo')) { 
        window.location.reload();
        localStorage.setItem('foo', 'no reload'); 
      } 
    }
    
  }
  

}
