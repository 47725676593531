<div id="myNav2" class="overlay2">
    <a href="javascript:void(0)" class="closebtn" (click)="closeNav2()"></a>
    <div id="overlay-content2" class="overlay-content2">
        <div id="overlaytext2" class="overlaytext side">
            <div id="halfheight1" class="halfheight">
                <h3>Depotdienstleistungen</h3>
                <h1>vollcontainer</h1>
            </div>
            <div id="halfheight2" class="halfheight">
                <div class="leftsidetext">
                    <div class="line">
                        <div class="leftofline">
                            <p>Gate</p>
                        </div>
                        <div class="rightofline">
                            <p>in - €40,00 / out - €40,00</p>
                        </div>
                    </div>
                    <div class="line">
                        <div class="leftofline">
                            <p>Storage 20‘ Container</p>
                        </div>
                        <div class="rightofline">
                            <p>€ 5,00 p.Tg.</p>
                        </div>
                    </div>
                    <div class="line">
                        <div class="leftofline">
                            <p>Storage 40‘-40HC Container</p>
                        </div>
                        <div class="rightofline">
                            <p>€ 10,00 p.Tg.</p>
                        </div>
                    </div>
                    <div class="line">
                        <div class="leftofline">
                            <p>Internes Handling</p>
                        </div>
                        <div class="rightofline">
                            <p>€ 25,00</p>
                        </div>
                    </div>

                </div>
                <div class="rightsidetext">
                    <div class="line">
                        <div class="leftofline">
                            <p>Storage 20'/40‘ Reefer Container</p>
                        </div>
                        <div class="rightofline">
                            <p>150,00 € p.Tg.</p>
                        </div>
                    </div>
                    <div class="line">
                        <div class="leftofline">
                            <p>Temperatureinstellung</p>
                        </div>
                        <div class="rightofline">
                            <p>Inklusive</p>
                        </div>
                    </div>
                    <div class="line">
                        <div class="leftofline">
                            <p>Monitoring (Reefer Container 3 x p. Arbeit’s Tag)-</p>
                        </div>
                        <div class="rightofline">
                            <p>Inklusive </p>
                        </div>
                    </div>
                    <div class="line">
                        <div class="leftofline">
                            <p>Foto Set(8-10 St.)</p>
                        </div>
                        <div class="rightofline">
                            <p>€ 25,00 p.Ctr.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div id="overlayimage2" class="overlayimage side">
            <img src="/assets/img/secondpanel.jpg" class="desktopimage" />
            <img src="/assets/img/secondpanelm.jpg" class="mobileimage" />
        </div>
    </div>
</div>