<mat-dialog-content>
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <span>IMPRESSUM:</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <span>BESETZT JUWEL.</span>
            </div>
            <div class="col-md-6">
                <span>VERTRETUNG:</span>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <span>§ 5 TMG</span>
            </div>
            <div class="col-md-6">
                <span>BIMICON GMBH WIRD DURCH MICHAEL BUBLIKOV</span>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-6">
                <span>BESITZ UND KONTAKT:</span>
            </div>
            <div class="col-md-6">
                <span>UMSATZSTEUER-ID:</span>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-6">
                <span>BIMICON GMBH</span>
            </div>
            <div class="col-md-6">
                <span>DE 177993448</span>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-6">
                <span>MERKURRING 94</span>
            </div>
            <div class="col-md-6">
                <span>BILDER UND BILDER:</span>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-6">
                <span>22143 HAMBURG</span>
            </div>
            <div class="col-md-6">
                <span>QUELLEN DER QUELLE FÜR BENUTZTEES BILDER- UND GRAFIKMATERIAL:
                </span>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-6">
                <span>TELEFON: 040 593958-0</span>
            </div>
            <div class="col-md-6">
                <span>HTTP://WWW.FOTOLIA.COM</span>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-md-6">
                <span>FAX: 040 593958-20
                </span>
            </div>
            <div class="col-md-6">
                <span>E-MAIL: INFO@BIMICON.DE</span>
            </div>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>