import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agbcomponent',
  templateUrl: './agbcomponent.component.html',
  styleUrls: ['./agbcomponent.component.css']
})
export class AgbcomponentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
