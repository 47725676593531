<mat-dialog-content>
    <p class="p1"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Diese Datenschutz-Information vergoldet f&uuml;r die Datenverarbeitung durch:&nbsp;</span></span></p>
<p class="p1"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Bimicon Container Service GmbH</span></span></p>
<p class="p1"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Merkurring 94</span></span></p>
<p class="p1"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">22143 Hamburg&nbsp;</span></span></p>
<p class="p1"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Tel 040 593958-0</span></span></p>
<p class="p1"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Fax 040 593958-20</span></span></p>
<p class="p1"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">E-Mail: </span></span><a href="mailto:info@bimicon.de"><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">info@bimicon.de</span></span></a>&nbsp;</p>
<p class="p1"><strong><em><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Erhebung und Speicherung personenbezogene Daten sowie Kunst und Zweck von deren Verwendung</span></span></em></strong>&nbsp;</p>
<p class="p1"><em><span style="vertical-align: inherit;"><span style="vertical-align: inherit;">Beim Besuch der Website</span></span></em>&nbsp;</p>
<p class="p1">Beim Aufrufen unserer Website www.bimicon.de werden durch den auf Ihrem Endger&auml;t zum Einsatz kommenden Browser automatisch Informationen an den Server unserer Website gesendet. Diese Informationen werden tempor&auml;r in einem sog. Logfile gespeichert. Folgende Informationen werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten L&ouml;schung, in der Regel nach Ablauf einer Woche, gespeichert:&nbsp;</p>
<ul>
<li>IP-Adresse des anfragenden Rechners,</li>
<li>Datum und Uhrzeit des Zugriffs,</li>
<li>Name und URL der abgerufenen Datei,</li>
<li>Website, von der aus der Zugriff erfolgt (Referrer-URL),</li>
<li>verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie der Name Ihres Access-Providers.&nbsp;</li>
</ul>
<p class="p1">Die genannten Daten werden durch uns zu folgenden Zwecken verarbeitet:&nbsp;</p>
<ul>
<li>Gew&auml;hrleistung eines reibungslosen Verbindungsaufbaus der Website,</li>
<li>Gew&auml;hrleistung einer komfortablen Nutzung unserer Website,</li>
<li>Auswertung der Systemsicherheit und -stabilit&auml;t</li>
<li>Aufkl&auml;rung etwaiger missbr&auml;uchlicher Seitenzugriffe (DoS/DDoS-Attacken o.&auml;.) sowie</li>
<li>zu weiteren administrativen Zwecken.&nbsp;</li>
</ul>
<p class="p1">Die Rechtsgrundlage f&uuml;r die Datenverarbeitung ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO. Unser berechtigtes Interesse folgt aus oben aufgelisteten Zwecken zur Datenerhebung. In der Regel verwenden wir die erhobenen Daten nicht zu dem Zweck, R&uuml;ckschl&uuml;sse auf Ihre Person zu ziehen. Dies behalten wir uns allenfalls f&uuml;r den Fall vor, dass dies erforderlich wird, um missbr&auml;uchliche Seitenzugriffe aufzukl&auml;ren.</p>
<p class="p1"><em> Bei Kontaktaufnahme mit uns</em>&nbsp;</p>
<p class="p1">Bei Fragen jeglicher Art bieten wir Ihnen die M&ouml;glichkeit, mit uns telefonisch oder aber per E-Mail Kontakt aufzunehmen. Dazu haben wir auf der Website unsere Kontaktdaten angegeben.&nbsp;</p>
<p class="p1">Wir bieten Ihnen au&szlig;erdem die M&ouml;glichkeit, mit uns &uuml;ber verschiedene auf der Webseite bereitgestellte Formulare Kontakt aufzunehmen. So k&ouml;nnen Sie uns &uuml;ber ein Web-Formular um einen pers&ouml;nlichen R&uuml;ckruf bitten. Hierf&uuml;r ben&ouml;tigen wir Ihren Namen und Ihre Telefonnummer. Au&szlig;erdem k&ouml;nnen Sie direkt &uuml;ber die Webseite ein Angebot von uns abfordern. Hierf&uuml;r ben&ouml;tigen wir Ihre Kontaktdaten sowie Ihr Produktinteresse, um Ihnen ein passgenaues Angebot zukommen lassen zu k&ouml;nnen.&nbsp;&nbsp;</p>
<p class="p1">Die Datenverarbeitung zum Zwecke der Kontaktaufnahme mit uns erfolgt nach Art. 6 Abs. 1 S. 1 lit. a DS-GVO auf Grundlage Ihrer freiwillig erteilten Einwilligung bzw. bei (vor-)vertraglichen Anfragen auf der Grundlage von Art. 6 Abs. 1 lit. b DS-GVO. Die im Rahmen Ihrer Kontaktaufnahme mit uns erhobenen und verarbeiteten Daten werden von uns nach Erledigung der Anfrage und ggf. nach Ablauf gesetzlicher Aufbewahrungsfristen gel&ouml;scht.&nbsp;</p>
<p class="p1"><em>Weitergabe von Daten</em>&nbsp;</p>
<p class="p1">Eine &Uuml;bermittlung Ihrer pers&ouml;nlichen Daten an Dritte zu anderen als den im Folgenden aufgef&uuml;hrten Zwecken findet nicht statt. Wir geben Ihre pers&ouml;nlichen Daten, die wir &uuml;ber diese Webseite erhoben haben, nur an Dritte weiter, wenn:&nbsp;</p>
<ul>
<li>Sie Ihre nach Art. 6 Abs. 1 S. 1 lit. a DS-GVO ausdr&uuml;ckliche Einwilligung dazu erteilt haben,</li>
<li>die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. f DS-GVO zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen erforderlich ist und kein Grund zur Annahme besteht, dass Sie ein &uuml;berwiegendes schutzw&uuml;rdiges Interesse an der Nichtweitergabe Ihrer Daten haben,</li>
<li>f&uuml;r den Fall, dass f&uuml;r die Weitergabe nach Art. 6 Abs. 1 S. 1 lit. c DS-GVO eine gesetzliche Verpflichtung besteht, sowie</li>
<li>dies gesetzlich zul&auml;ssig und nach Art. 6 Abs. 1 S. 1 lit. b DS-GVO f&uuml;r die Abwicklung von Vertragsverh&auml;ltnissen mit Ihnen erforderlich ist.&nbsp;</li>
</ul>
<p class="p1"><em>Cookies</em>&nbsp;</p>
<p class="p1">Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um kleine Dateien, die Ihr Browser automatisch erstellt und die auf Ihrem Endger&auml;t (Laptop, Tablet, Smartphone o.&auml;.) gespeichert werden, wenn Sie unsere Seite besuchen. In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem spezifisch eingesetzten Endger&auml;t ergeben. Dies bedeutet jedoch nicht, dass wir dadurch unmittelbar Kenntnis von Ihrer Identit&auml;t erhalten.&nbsp;</p>
<p class="p1">Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots f&uuml;r Sie angenehmer zu gestalten. So setzen wir sogenannte Session-Cookies ein, um zu erkennen, dass Sie einzelne Seiten unserer Website bereits besucht haben. Diese werden nach Verlassen unserer Seite automatisch gel&ouml;scht.&nbsp;</p>
<p>Dar&uuml;ber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit tempor&auml;re Cookies ein, die f&uuml;r einen bestimmten festgelegten Zeitraum auf Ihrem Endger&auml;t gespeichert</p>
<p>werden. Besuchen Sie unsere Seite erneut, um unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns waren und welche Eingaben und Einstellungen sie get&auml;tigt haben, um diese nicht noch einmal eingeben zu m&uuml;ssen.&nbsp;</p>
<p>Zum anderen setzten wir Cookies ein, um die Nutzung unserer Website statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes f&uuml;r Sie auszuwerten (dazu s.u. unter &bdquo;<strong>Analyse-Tools</strong>&ldquo;). Diese Cookies erm&ouml;glichen es uns, bei einem erneuten Besuch unserer Seite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies werden nach einer jeweils definierten Zeit automatisch gel&ouml;scht.&nbsp;</p>
<p>Die durch Cookies verarbeiteten Daten verarbeiten wir auf Basis unserer berechtigten Interessen, soweit es sich um Cookies handelt, die allein der Optimierung Ihres Besuchs auf unserer Seite dienen (Art. 6 Abs. 1 lit f DS-GVO). Die durch Cookies von &bdquo;Analyse-Tools&ldquo; verarbeiteten Daten verarbeiten wir ausschlie&szlig;lich auf Grundlage Ihrer uns zuvor erteilten Einwilligung, die Sie uns bei Aufruf der Seite durch Bet&auml;tigung der entsprechenden Schaltfl&auml;che in dem &bdquo;Cookie-Banner&ldquo; freiwillig erteilen k&ouml;nnen (Art. 6 Abs. 1 lit. a DS-GVO).&nbsp;&nbsp;</p>
<p>Sie k&ouml;nnen Ihren Browser auch so konfigurieren, dass keine Cookies auf Ihrem Computer (mehr) gespeichert werden oder stets ein Hinweis erscheint, bevor ein neuer Cookie angelegt wird. Die vollst&auml;ndige Deaktivierung von Cookies kann jedoch dazu f&uuml;hren, dass Sie nicht alle Funktionen unserer Website nutzen k&ouml;nnen.&nbsp;</p>
<p class="p1"><em>Analyse-Tools</em>&nbsp;</p>
<p>(i) Google Analytics&nbsp;</p>
<p>Zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden Optimierung unserer Seiten nutzen wir Google Analytics, ein Webanalysedienst der Google Inc. (https://www.google.de/</p>
<p>intl/de/about/) (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; im Folgenden &bdquo;Google&ldquo;). Zum anderen setzen wir die Tracking-Ma&szlig;nahmen ein, um die Nutzung unserer Webseite statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes f&uuml;r Sie auszuwerten. Diese Verarbeitungen f&uuml;hren wir ausschlie&szlig;lich auf der Grundlage Ihrer uns zuvor erteilten Einwilligung durch (dazu s.o. unter &bdquo;<strong>Cookies</strong>&ldquo;)&nbsp;</p>
<p>Google Analytics erstellt f&uuml;r uns pseudonymisierte Nutzungsprofile. Die durch den Google Analytics-Cookie erzeugten Informationen &uuml;ber Ihre Benutzung dieser Website wie&nbsp;</p>
<ul>
<li>Browser-Typ/-Version,</li>
<li>verwendetes Betriebssystem,</li>
<li>Referrer-URL (die zuvor besuchte Seite),</li>
<li>Hostname des zugreifenden Rechners (IP-Adresse),</li>
<li>Uhrzeit der Serveranfrage,&nbsp;</li>
</ul>
<p>werden an einen Server von Google in den USA &uuml;bertragen und dort gespeichert. Die Informationen werden verwendet, um die Nutzung der Website auszuwerten, um Reports &uuml;ber die Websiteaktivit&auml;ten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen zu Zwecken der Marktforschung und bedarfsgerechten Gestaltung dieser Internetseiten zu erbringen. Auch werden diese Informationen gegebenenfalls an Dritte &uuml;bertragen, sofern dies gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag verarbeiten. Es wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google zusammengef&uuml;hrt. Die IP-Adressen werden anonymisiert, so dass eine Zuordnung nicht m&ouml;glich ist (IP-Masking).&nbsp;</p>
<p>Im Zusammenhang mit Google Analytics wird auch der Google Tag Manager eingesetzt, der weitere Auswertungsm&ouml;glichkeiten zur Webseitennutzung erlaubt. Durch den Google Tag Manager werden allerdings keine Cookies eingesetzt und es werden keine personenbezogene Daten erfasst.&nbsp;</p>
<p>Sie k&ouml;nnen die Installation der Cookies durch eine entsprechende Einstellung der Browser-Software auch nach einer zuvor erteilten Einwilligung jederzeit wieder unterbinden; wir weisen jedoch darauf hin, dass in diesem Fall gegebenenfalls nicht s&auml;mtliche Funktionen dieser Website vollumf&auml;nglich genutzt werden k&ouml;nnen.&nbsp;</p>
<p>Sie k&ouml;nnen dar&uuml;ber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) sowie die Verarbeitung dieser Daten durch Google unterbinden, indem Sie ein Browser-Add-on herunterladen und installieren (https://tools.google.com/dlpage/gaoptout?hl=de).&nbsp;</p>
<p>Alternativ zum Browser-Add-on, insbesondere bei Browsern auf mobilen Endger&auml;ten, k&ouml;nnen Sie die Erfassung durch Google Analytics zudem unterbinden, indem Sie auf diesen <strong><a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">[block Google Analytics]</a></strong>&nbsp; klicken. Es wird ein Opt-out-Cookie gesetzt, das die zuk&uuml;nftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert. Der Opt-out-Cookie gilt nur in diesem Browser und nur f&uuml;r unsere Website und wird auf Ihrem Ger&auml;t abgelegt. L&ouml;schen Sie die Cookies in diesem Browser, m&uuml;ssen Sie das Opt-out-Cookie erneut setzen.&nbsp;</p>
<p>Weitere Informationen zum Datenschutz im Zusammenhang mit Google Analytics finden Sie etwa in der Google Analytics-Hilfe (https://support.google.com/analytics/ answer/6004245?hl=de).&nbsp;</p>
<p class="p1">Google hat sich dem EU-US Privacy Shield unterworfen, https://www.privacyshield.gov/EU-US-Framework.&nbsp;</p>
<p class="p1">(ii) Google AdWords Conversion Tracking / Google Remarketing / Double Click&nbsp;</p>
<p class="p1">Um die Nutzung dieses Onlineangebots statistisch zu erfassen und zum Zwecke der Optimierung des Onlineangebots f&uuml;r die Nutzer auszuwerten, nutzen wir ferner das Google Conversion Tracking. Diese Tracking-Ma&szlig;nahmen werden auf Grundlage des Art. 6 Abs. 1 S. 1 lit. a DS-GVO, n&auml;mlich erst nach einer entsprechenden Einwilligung der Nutzer durchgef&uuml;hrt. Dabei wird von Google Adwords ein Cookie (dazu s.o. unter &bdquo;<strong>Cookies</strong>&ldquo;) auf dem Endger&auml;t des Nutzers gesetzt, sofern der Nutzer &uuml;ber eine Google-Anzeige auf unser Onlineangebot gelangt ist. Diese Cookies verlieren nach 30 Tagen ihre G&uuml;ltigkeit und dienen nicht der pers&ouml;nlichen Identifizierung.&nbsp;</p>
<p class="p1">Besucht der Nutzer bestimmte Seiten unseres Onlineangebots und ist das Cookie noch nicht abgelaufen, k&ouml;nnen Google und wir erkennen, dass der Nutzer auf die Anzeige geklickt hat und zu dieser Seite weitergeleitet wurde. Jeder Adwords-Kunde erh&auml;lt ein anderes Cookie. Cookies k&ouml;nnen somit nicht &uuml;ber die Webseiten von Adwords-Kunden nachverfolgt werden.&nbsp;</p>
<p class="p1">Die mithilfe des Conversion-Cookies eingeholten Informationen dienen dazu, Conversion-Statistiken f&uuml;r Adwords-Kunden zu erstellen. Die Adwords-Kunden erfahren die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen sich Nutzer pers&ouml;nlich identifizieren lassen.</p>
<p class="p1">&nbsp;Wenn der Nutzer nicht an dem Tracking-Verfahren teilnehmen m&ouml;chte, kann er auch das hierf&uuml;r erforderliche Setzen eines Cookies (wieder) ablehnen &ndash; etwa per Browser-Einstellung, die das automatische Setzen von Cookies generell deaktiviert. Der Nutzer kann Cookies f&uuml;r Conversion-Tracking auch deaktivieren, indem er seinen Browser so einstellt, dass Cookies von der Domain &bdquo;www.googleadservices.com&ldquo; blockiert werden.&nbsp;</p>
<p class="p1">Auf unseren Seiten sind au&szlig;erdem verschiedene Werbefunktionen im Zusammenhang mit den Analyse-Instrumenten von Google Analytics aktiviert, n&auml;mlich die Funktionen &bdquo;Remarketing mit Google Analytics&ldquo; und &bdquo;Berichte zur Leistung nach demografischen Merkmalen und Interessen&ldquo;. Diese Instrumente setzen wir auf der Grundlage des Art. 6 Abs. 1 lit. a DS-GVO ein, um Ihnen Informationen &uuml;ber unsere Produkte und Dienstleistungen zielgerichteter zuspielen zu k&ouml;nnen. Das geschieht durch folgende Ma&szlig;nahmen:&nbsp;&nbsp;</p>
<p class="p1">Mit&nbsp;&bdquo;Remarketing von Google Analytics&ldquo; lassen sich Webseiten-Nutzer in verschiedene Segmente unterteilen. Dadurch k&ouml;nnen wir f&uuml;r jeden Nutzer relevante Anzeigen aus unseren Kampagnen im Google Displaynetzwerk und Suchnetzwerk ausliefern&nbsp;&ndash; je nachdem, welche Aktionen der jeweilige Nutzer auf einer unserer Webseiten ausgef&uuml;hrt hat. Diese Funktion wird also Ihr Verhalten auswerten und uns Angaben dar&uuml;ber liefern, die wir f&uuml;r zielgerichtete Informationen zu unseren Produkten und Dienstleistungen verwenden m&ouml;chten. Solche Angaben k&ouml;nnen zum Beispiel darin bestehen, dass Sie bestimmte Produkte angesehen, aber nicht in den Warenkorb gelegt haben, oder dass Sie bestimmte Produkte zwar in den Warenkorb gelegt, aber nicht gekauft haben.&nbsp;</p>
<p class="p1">Durch den Einsatz der &bdquo;<a href="https://support.google.com/analytics/answer/2799357">Berichte zur Leistung nach demografischen Merkmalen und Interessen</a>&ldquo; haben wir die M&ouml;glichkeit, die Besucher unserer Webseiten nach Alter, Geschlecht und Interessen aufzuschl&uuml;sseln. Auf dieser Basis werden wir dann verschiedene Segmente, also Nutzergruppen f&uuml;r die zielgerichtete Information &uuml;ber unsere Produkte und Dienstleistungen mittels &bdquo;Remarketing von Google Analytics&ldquo; erstellen. Hierzu werden verschiedene Cookies eingesetzt, je nachdem ob Sie unsere Webseiten mit einem Rechner oder mit Ihrem Smartphone oder Tablet besuchen. Wir k&ouml;nnen mithilfe dieser Cookies Angaben &uuml;ber Ihr Alter (Altersgruppen 18-24, 25-34-, 35-44, 45-54, 55-64, 65+), Ihr Geschlecht und Ihre Interessen (z.B. &bdquo;sportbegeistert&ldquo; o.&auml;.) herausfinden und f&uuml;r weitere Informationen &uuml;ber unsere Produkte und Dienstleistungen nutzen.&nbsp;</p>
<p class="p1">Zus&auml;tzlich gilt: Sofern Sie gegen&uuml;ber Google Ihre Zustimmung erteilt haben, dass Ihr Web- und App-Browserverlauf von Google mit Ihrem Google-Konto verkn&uuml;pft wird und Informationen aus Ihrem Google-Konto zum Personalisieren von Anzeigen verwendet werden, verwendet Google Ihre Daten zusammen mit Google Analytics-Daten, um f&uuml;r uns Zielgruppenlisten f&uuml;r ger&auml;te&uuml;bergreifendes Remarketing zu erstellen. Dazu werden von Google Analytics zun&auml;chst auf unseren Webseiten Google-authentifizierte Client-IDs erfasst, die mit einem Google-Konto verkn&uuml;pft sind (also personenbezogene Daten). Anschlie&szlig;end werden von Google Analytics diese IDs vor&uuml;bergehend mit unseren Google Analytics-Daten verkn&uuml;pft, um uns zielgruppen-optimierte Werbung zu erm&ouml;glichen, insbesondere Sie als Nutzer auch dann noch mit zielgerichteter Werbung zu erreichen, wenn Sie unsere Seiten zun&auml;chst mit einem Ger&auml;t besucht haben, Sie aber sp&auml;ter auf einem anderen Ger&auml;t suchen oder surfen.&nbsp;</p>
<p>Haben Sie kein Google-Konto oder haben Sie Google gegen&uuml;ber keine Zustimmung zur werbeorientierten Auswertung Ihrer Internetnutzung erteilt, erfolgt auch durch uns keinerlei Auswertung im vorstehenden Sinne.&nbsp;</p>
<p class="p1">Googles Datenschutzbelehrung zum Conversion-Tracking ist hier abrufbar: https://services.google.com/sitestats/de.html.&nbsp;</p>
<p class="p1"><strong><em>Betroffenenrechte</em></strong>&nbsp;</p>
<p class="p1">Sie haben das Recht:&nbsp;</p>
<ul>
<li>gem&auml;&szlig; Art. 15 DS-GVO Auskunft &uuml;ber Ihre von uns verarbeiteten personenbezogenen Daten zu verlangen. Insbesondere k&ouml;nnen Sie Auskunft &uuml;ber die Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von Empf&auml;ngern, gegen&uuml;ber denen Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts auf Berichtigung, L&ouml;schung, Einschr&auml;nkung der Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer Daten, sofern diese nicht bei uns erhoben wurden, sowie &uuml;ber das Bestehen einer automatisierten Entscheidungsfindung einschlie&szlig;lich Profiling und ggf. aussagekr&auml;ftigen Informationen zu deren Einzelheiten verlangen;&nbsp;</li>
</ul>
<p class="p1">Wir d&uuml;rfen Ihnen gegen&uuml;ber die Auskunft nur verweigern, wenn und soweit durch die Auskunft Informationen offenbart w&uuml;rden, die nach einer Rechtsvorschrift oder ihrem Wesen nach, insbesondere wegen der &uuml;berwiegenden berechtigten Interessen eines Dritten, geheim gehalten werden m&uuml;ssen (&sect; 29 Abs. 1 Satz 2 BDSG), die zust&auml;ndige &ouml;ffentliche Stelle uns gegen&uuml;ber festgestellt hat, dass das Bekanntwerden der Daten die &ouml;ffentliche Sicherheit oder Ordnung gef&auml;hrden oder sonst dem Wohl des Bundes oder eines Landes Nachteile bereiten w&uuml;rde (&sect; 34 Abs. 1 Nr. 1 BDSG i.V.m. &sect; 33 Abs. 1 Nr. 2 lit. b BDSG), oder die Daten nur deshalb gespeichert sind, weil sie aufgrund gesetzlicher oder satzungsm&auml;&szlig;iger Aufbewahrungsvorschriften nicht gel&ouml;scht werden d&uuml;rfen, oder ausschlie&szlig;lich Zwecken der Datensicherung oder der Datenschutzkontrolle dienen und die Auskunftserteilung einen unverh&auml;ltnism&auml;&szlig;igen Aufwand erfordern w&uuml;rde sowie eine Verarbeitung zu anderen Zwecken durch geeignete technische und organisatorische Ma&szlig;nahmen ausgeschlossen ist (&sect; 34 Abs. 1 Nr. 2 BDSG).&nbsp;</p>
<ul>
<li>gem&auml;&szlig; Art. 16 DS-GVO unverz&uuml;glich die Berichtigung unrichtiger oder Vervollst&auml;ndigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;&nbsp;</li>
</ul>
<ul>
<li>gem&auml;&szlig; Art. 17 DS-GVO die L&ouml;schung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Aus&uuml;bung des Rechts auf freie Meinungs&auml;u&szlig;erung und Information, zur Erf&uuml;llung einer rechtlichen Verpflichtung, aus Gr&uuml;nden des &ouml;ffentlichen Interesses oder zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen erforderlich ist;&nbsp;</li>
</ul>
<ul>
<li>gem&auml;&szlig; Art. 18 DS-GVO die Einschr&auml;nkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die Verarbeitung unrechtm&auml;&szlig;ig ist, Sie aber deren L&ouml;schung ablehnen und wir die Daten nicht mehr ben&ouml;tigen, Sie jedoch diese zur Geltendmachung, Aus&uuml;bung oder Verteidigung von Rechtsanspr&uuml;chen ben&ouml;tigen oder Sie gem&auml;&szlig; Art. 21 DS-GVO Widerspruch gegen die Verarbeitung eingelegt haben;&nbsp;</li>
</ul>
<ul>
<li>gem&auml;&szlig; Art. 20 DS-GVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, g&auml;ngigen und maschinenlesebaren Format zu erhalten oder die &Uuml;bermittlung an einen anderen Verantwortlichen zu verlangen;&nbsp;</li>
</ul>
<ul>
<li>gem&auml;&szlig; Art. 7 Abs. 3 DS-GVO Ihre einmal erteilte Einwilligung jederzeit gegen&uuml;ber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser Einwilligung beruhte, f&uuml;r die Zukunft nicht mehr fortf&uuml;hren d&uuml;rfen,&nbsp;</li>
</ul>
<ul>
<li>sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gem&auml;&szlig; Art. 6 Abs. 1 S. 1 lit. f DS-GVO verarbeitet werden, gem&auml;&szlig; Art. 21 DS-GVO Widerspruch gegen die Verarbeitung Ihrer personenbezogenen Daten einzulegen, soweit daf&uuml;r Gr&uuml;nde vorliegen, die sich aus Ihrer besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer besonderen Situation von uns umgesetzt wird.&nbsp;</li>
</ul>
<p class="p1">Dar&uuml;ber hinaus haben Sie ein allgemeines Beschwerderecht bei der f&uuml;r Sie zust&auml;ndigen Datenschutzaufsichtsbeh&ouml;rde. Die f&uuml;r die Bimicon GmbH zust&auml;ndige Beh&ouml;rde ist der &bdquo;Hamburgische Beauftragte f&uuml;r Datenschutz und Informationsfreiheit&ldquo;.&nbsp;</p>
<p class="p1">M&ouml;chten Sie von Ihrem Widerrufs- oder Widerspruchsrecht Gebrauch machen, gen&uuml;gt eine E-Mail an&nbsp;<a href="mailto:info@bimicon.de">info@bimicon.de</a></p>
<p class="p1"><strong><em>Datensicherheit</em></strong>&nbsp;</p>
<p class="p1">Wir verwenden innerhalb des Website-Besuchs das verbreitete SSL-Verfahren (Secure Socket Layer) in Verbindung mit der jeweils h&ouml;chsten Verschl&uuml;sselungsstufe, die von Ihrem Browser unterst&uuml;tzt wird. In der Regel handelt es sich dabei um eine 256 Bit Verschl&uuml;sselung. Falls Ihr Browser keine 256-Bit Verschl&uuml;sselung unterst&uuml;tzt, greifen wir stattdessen auf 128-Bit v3 Technologie zur&uuml;ck. Ob eine einzelne Seite unseres Internetauftrittes verschl&uuml;sselt &uuml;bertragen wird, erkennen Sie an der geschlossenen Darstellung des Schl&uuml;ssel- beziehungsweise Schloss-Symbols in der unteren Statusleiste Ihres Browsers.&nbsp;</p>
<p class="p1">Wir bedienen uns im &Uuml;brigen geeigneter technischer und organisatorischer Sicherheitsma&szlig;nahmen, um Ihre Daten gegen zuf&auml;llige oder vors&auml;tzliche Manipulationen, teilweisen oder vollst&auml;ndigen Verlust, Zerst&ouml;rung oder gegen den unbefugten Zugriff Dritter zu sch&uuml;tzen. Unsere Sicherheitsma&szlig;nahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.&nbsp;</p>
<p class="p1"><strong><em>Aktualit&auml;t und &Auml;nderung dieser Datenschutzerkl&auml;rung</em></strong>&nbsp;</p>
<p class="p1">Diese Datenschutzerkl&auml;rung ist aktuell g&uuml;ltig und hat den Stand Mai 2018. Durch die Weiterentwicklung unserer Website und Angebote dar&uuml;ber oder aufgrund ge&auml;nderter gesetzlicher beziehungsweise beh&ouml;rdlicher Vorgaben kann es notwendig werden, diese Datenschutzerkl&auml;rung zu &auml;ndern. Die jeweils aktuelle Datenschutzerkl&auml;rung kann jederzeit auf der Website unter https://www.bimicon.de/datenschutz von Ihnen abgerufen und ausgedruckt werden.</p>
<p>&nbsp;</p>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
