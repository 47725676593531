<app-header></app-header>
<div class="content">
    <div class="blackbackround">
        <div class="slider">
            <app-slider></app-slider>
        </div>
        <app-depo></app-depo>
    </div>
    <div class="imageline">
        <div class="imagecon">
            <img src="/assets/img/mask.jpg"/>
        </div>
    </div>
    <div class="whitebackground">
        
        
        <app-depocharacter></app-depocharacter>
        <app-sendemail></app-sendemail>
    </div>   
    <app-footer></app-footer> 
</div>
