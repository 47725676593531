<div class="depocharacter">
    <div class="container">
        <div class="backgroundimage">
            <img src="/assets/img/Vector.png" />
        </div>
        <div class="content">

            <h1>Charakteristiken des Containerdepots</h1>
            <div class="side">
                <h2>15 000 m²</h2>
                <p>asphaltierte Freifläche</p>
                <h2>1 000 m²</h2>
                <p>überdachte Fläche</p>
                <div class="imagecontainer">
                    <img src="/assets/img/depoch.png" />
                </div>

            </div>
            <div class="side">

                <h2>2 000 TEU</h2>
                <p>Kapazität</p>
                <h2>100–150 Container</h2>
                <p>Umschlagskapazität pro Tag</p>
                <h2>20 000–30 000 Container</h2>
                <p>Umschlagskapazität pro Jahr</p>
                <h2>Leercontainerstapler mit bis zu 10 Tonnen Hubkraft</h2>
                <h2>22x</h2>
                <p>Elektrische Anschlüsse für PTI</p>
                <h2>Im Schnitt mehr als 14 Jahre Pro-Kopf Erfahrung unserer Kühltechniker, Schweißer und Staplerfahrer
                </h2>
                <br/>
                <h2>
                    Hafennahe Lage mit direkter Autobahnanbindung und kurzen Wegen zu den Kais
                </h2>
            </div>
        </div>
    </div>

</div>