<div id="myNavRightFirst" class="overlay2">
    <a href="javascript:void(0)" class="closebtn" (click)="closeNavRightFirst()"></a>
    <div id="overlay-content2" class="overlay-content2">
        <div id="overlaytextRightFirst" class="overlaytext side">
            <div id="halfheight1" class="halfheight">
                <h1>Seefracht Weltweit</h1>
            </div>
            <div id="halfheight2" class="halfheight">
                <div class="leftsidetext">
                    <div class="line">
                        <div class="leftofline">
                            <p>Vollcontainer Seefracht (FCL – Full Container Load)</p>
                        </div>
                        <div class="rightofline">
                            <p></p>
                        </div>
                    </div>
                    <div class="line">
                        <div class="leftofline">
                            <p>Vorlauf (Pre-Carriage) / Nachlauf (On-Carriage)</p>
                        </div>
                        <div class="rightofline">
                            <p></p>
                        </div>
                    </div>
                    <div class="line">
                        <div class="leftofline">
                            <p>Erstellung der Exportdokumente in Deutschland</p>
                        </div>
                        <div class="rightofline">
                            <p></p>
                        </div>
                    </div>
                    <div class="line">
                        <div class="leftofline">
                            <p>Importzollabfertigung in Deutschland</p>
                        </div>
                        <div class="rightofline">
                            <p></p>
                        </div>
                    </div>
                    <div class="line">
                        <div class="leftofline">
                            <p>Tel.</p>
                        </div>
                        <div class="rightofline">
                            <p>+49 40 59395815</p>
                        </div>
                    </div>

                </div>
                <div class="rightsidetext">
                    <div class="line">
                        <div class="leftofline">
                            <p>Containerzustellung</p>
                        </div>
                        <div class="rightofline">
                            <p></p>
                        </div>
                    </div>
                    <div class="line">
                        <div class="leftofline">
                            <p>Kombinierter Transport</p>
                        </div>
                        <div class="rightofline">
                            <p></p>
                        </div>
                    </div>
                    <div class="line">
                        <div class="leftofline">
                            <p>Zwischenlagerung auf unserem eigenen Depot in Hamburg</p>
                        </div>
                        <div class="rightofline">
                            <p></p>
                        </div>
                    </div>
                    <div class="line">
                        <div class="leftofline">
                            <p>Beratung</p>
                        </div>
                        <div class="rightofline">
                            <p></p>
                        </div>
                    </div>
                    <div class="line">
                        <div class="leftofline">
                            <p>Email</p>
                        </div>
                        <div class="rightofline">
                            <p>info@bimicon.de</p>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <div id="rightfirstoverlayimage" class="overlayimage side">
            <img src="/assets/img/firstrightoverlay.jpg" class="desktopimage" />
            <img src="/assets/img/secondpanelm.jpg" class="mobileimage" />
        </div>
    </div>
</div>