import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { FormsModule} from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { DepoComponent } from './components/depo/depo.component';
import { DepocharacterComponent } from './components/depocharacter/depocharacter.component';
import { SliderComponent } from './components/slider/slider.component';
import { SecondoverlayComponent } from './components/secondoverlay/secondoverlay.component';
import { SendemailComponent } from './components/sendemail/sendemail.component';
import { HttpClientModule } from '@angular/common/http';
import { TestsendingComponent } from './components/testsending/testsending.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { HomeComponent } from './home/home.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { AgbcomponentComponent } from './agbcomponent/agbcomponent.component';
import { NgxSpinnerModule } from "ngx-spinner";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import { RightfirstoverlayComponent } from './components/rightfirstoverlay/rightfirstoverlay.component';
import { RightsecondoverlayComponent } from './components/rightsecondoverlay/rightsecondoverlay.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    DepoComponent,
    DepocharacterComponent,
    SliderComponent,
    SecondoverlayComponent,
    SendemailComponent,
    TestsendingComponent,
    DatenschutzComponent,
    HomeComponent,
    ImpressumComponent,
    AgbcomponentComponent,
    RightfirstoverlayComponent,
    RightsecondoverlayComponent,
    
  ],
  imports: [
    BrowserModule,
    // FormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    MatDialogModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
