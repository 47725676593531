<mat-dialog-content>
    <div class="container">
        <h1>ALLGEMEINE GESCHÄFTSBEDINGUNGEN DER
            BIMICON Container Terminal Service
        </h1>
        <h3>1. Geltungsbereich</h3>
        <p>Diese Bedingungen gelten in ihrer jeweils neuesten Fassung für alle, 
            auch künftigen Angebote, Lieferungen und Geschäftsabschlüsse. 
            Sie finden insbesondere Anwendung bei der Ausführung von Reparatur-, 
            Umbau-, Wartungs- und Montagearbeiten von Containern, Trailern, Chassis 
            oder dazugehörigem Gerät, im folgenden kurz „Container“ genannt, 
            auch wenn es sich nur um den Teil eines Containers handelt.
        </p>
        <h3>2. Vertragsabschluß</h3>
        <p>
            2.1. Unsere Angebote sind in jeder Hinsicht freibleibend. Aufträge sind in jedem Fall 
            für uns      
           erst bindend, wenn unsere Kostenvoranschläge schriftlich bestätigt sind.Maßgebend
           ist ausschließlich der schriftlich fixierte Vertragsinhalt unter Aufhebung aller vorher-
           gehenden Angaben, Zusicherungen und dergleichen, die nicht in dieser Auftrags-
           bestätigung enthalten sind. Änderungen bedürfen der Schriftform.
        </p>
        <p>
            2.2. Die Bestimmung von Umfang und Zweckmäßigkeit einer Reparatur obliegt allein dem
            Auftraggeber. Die Stellungnahme einer Klassifikationsgesellschaft dürfen wir unge-
            prüft unseren Arbeiten zugrunde legen.
        </p>
        <p>
            2.3. Sofern von unserer Seite oder durch unsere Vermittlung Umfuhren vorgenommen 
            werden, erfolgt dies im Auftrag bzw. auf Kosten des Auftraggebers. Eine Haftung für
            Schäden und Verluste ist - außer bei Voraussetzung grober Fahrlässigkeit oder
            Vorsatz - ausgeschlossen.
        </p>
        <p>
            2.4. Der Auftraggeber hat den Container am vereinbarten Ort so zu übergeben, daß von
            uns  mit den Arbeiten sofort begonnen werden kann.
        </p>
        <p>
            2.5. Wird uns nach Abschluß des Vertrages bekannt, daß sich der Auftraggeber bzw.
           Besteller in ungünstiger Vermögenslage befindet, so können wir Sicherheit für unsere
           Gegenleistung verlangen oder unter Anrechnung der von uns gemachten Auf-
           wendungen vom Vertrag zurücktreten.
        </p>
        <h3>3. Preise</h3>
        <p>
            3.1. Angebots- und Rechnungspreise werden so kalkuliert, daß anfallendes Altmaterial
           ohne Vergütung in unser Eigentum übergeht.
        </p>
        <p>
            3.2. Unsere Preise beruhen auf dem zum Zeitpunkt des Angebotes maßgebenden Kosten-
            faktoren. Sollten sich die Kostenfaktoren bis zur Auftragserteilung oder während
            dessen Durchführung erhöhen, so kann der Auftragspreis im angemessenen 
            Verhältnis zur eingetretenen Änderung der Verhältnisse erhöht werden.
        </p>
        <p>
            3.3. Vereinbarte Preise gelten nur im Verhältnis zwischen uns und unseren Vertrags-
            partnern. Gegenüber Dritten findet der Tarif für die Leistungen der Kaiumschlags-
            betriebe in Hamburg (Kaitarif) Anwendung.
        </p>
        <h3>
            4. Zahlungsbedingungen
        </h3>
        <p>
            4.1. Zahlungen sind bei Abgabe bzw. Übergabe des Auftraggegenstandes spätestens
           jedoch nach Meldung der Fertigstellung und Aushändigung oder Übersendung ohne
           Abzug zu leisten.
        </p>
        <p>
            4.2. Die Zahlungen sind ausschließlich per Überweisung auf unser Bankkonto und in Euro
           zu leisten, innerhalb von 14 Tagen ab Rechnungsdatum.
        </p>
        <p>
            4.3. Die Ausübung des Zurückbehaltungsrechtes gegenüber unseren Rechnungs-
            forderungen ist ausgeschlossen. Ebenso ist die Aufrechnung ausgeschlossen, es sei
            denn, es wird mit einer unbestrittenen oder rechtskräftigen Forderung aufgerechnet.
        </p>
        <p>
            4.4. Zahlungsverzug tritt 15 Tage nach Rechnungsdatum ein,ohne daß es einer gesonderten
           Mahnung bedarf. Wir sind berechtigt Verzugszinsen in Höhe von 8% über dem
            jeweiligen Diskontsatz der Deutschen Bundesbank oder der tatsächlich höheren,
            von uns gezahlten Kreditzinsen zu berechnen.
        </p>
        <p>
            4.5. Bei Wartungs-, Fertigungs-, Reparatur- und Montagearbeiten stehen uns wegen
           unserer Forderungen aus dem Auftrag ein vertragliches Pfandrecht und ein Zurück-
           behaltungsrecht an dem aufgrund des Auftrages in unseren Besitz gelangten
           Auftragsgegenstand zu.  Das vertragliche Pfandrecht kann auch wegen Forderungen
           aus früher ausgeführten Arbeiten ,Ersatzteillieferungen und sonstigen Leistungen
           geltend gemacht werden, soweit sie mit dem Auftragsgegenstand im Zusammen-
           hang stehen. Für sonstige Ansprüche aus der Geschäftsverbindung gilt das
           vertragliche Pfandrecht nur, soweit diese unbestritten sind und ein rechtskräftiger
           Titel vorliegt.
        </p>
        <p>
            4.6. Tritt nach Abschluß des Vertrages ein die Zahlungsfähigkeit des Käufers gefährdender
            Umstand ein, so können wir Sicherheitsleistung oder Vorauszahlung verlangen.
        </p>
        <p>
            4.7   Bei Gefahr oder einer Nichtzahklung des Auftraggebers, für sämtliche Leistungen des
            Containerdepots, behält sich der Auftragnehmer vor, Container im equivalenten Wert zu der 
            offenen Forderung festzusetzen und zur Auslieferung nicht freizugeben, bis die Forderung 
            ohne Abzüge beglichen wurde.
        </p>
        <h3>5. Lieferzeit und Lieferungen</h3>
        <p>
            5.1. Die Lieferzeit ist auf Grundlage des bei Auftragserteilung bekannten Arbeitsumfanges
           soweit voraussehbar, jedoch unverbindlich bestimmt. Sie steht weiterhin unter dem
           Vorbehalt, daß keine Fälle höherer Gewalt , Betriebsstörungen, Streik, Aussperrung,
           Mangel an Rohstoffen oder ähnliche Ereignisse eintreten, die die Ausführung  des
           Auftrages erschweren, sei es bei unseren Zulieferern oder sonstigen Vertragspartnern.
           Die vorgenannten Umstände entlasten uns auch, wenn wir uns bereits im Verzug
           befinden. Die Lieferzeit verlängert sich in diesen Fällen um die erforderliche Zeit.
           Das gleiche gilt, wenn Ergänzungen des ursprünglichen Auftragumfanges vereinbart
           werden.
        </p>
        <p>
            5.2. Im Falle einer von uns zu vertetenen sonstigen Lieferungsverzögerung (Verzug)
            oder Lieferungsunmöglichkeit ist der Auftraggeber lediglich berechtigt, nach Setzung
           einer angemessenen Nachfrist von dem Auftrag zurückzutreten. Ansprüche auf
           Schadenersatz, gleich welcher Art und aus welchem Rechtsgrund sind dagegen
           ausgeschlossen, soweit auf unserer Seite nicht Vorsatz oder grobe Fahrlässigkeit
           gegeben sind.
    
        </p>
        <p>
            5.3. Wird die Lieferung und Leistung aus einem Grunde unmöglich, den keine der 
           Vertragsparteien zu vertreten haben, so sind beide Parteien nur zum Rücktritt
            berechtigt. Der Auftraggeber hat uns die entstandenen Kosten zu ersetzen,
            mindestens pauschal 15% des bestätigten Auftragwertes
        </p>
        <h3>6. Mängelrügen , Gewährleistung</h3>
        <p>
            6.1. Erkennbare Mängel sind uns bei Beendigung der Arbeiten, spätestens nach 
           Übergabe der Lieferung oder des Leistungsgegenstandes unverzüglich schriftlich
           anzuzeigen. Mängel, die auch bei sorgfältigster Prüfung nicht sofort erkennbar
           werden können, sind unverzüglich nach Entdeckung, spätestens jedoch innerhalb
           von 2 Wochen seit dem Liefer- bzw. Übergabetag schriftlich und spezifiziert zu
           rügen. Danach ist die Geltendmachung von Gewährleistungsansprüchen ausge-
           schlossen.
        </p>
        <p>
            6.2. Bei ordnungsgemäß gerügten und nachgewiesenen Mängeln sind wir nach unserer
           Wahl zur kostenlosen Nachbesserung in unserem Betrieb verpflichtet. Die Nach-
            besserung gilt als unzumutbar, wenn der dafür erforderliche Aufwand den Wert des
            ursprünglichen Liefer- und Leistungsgegenstandes übersteigt. Bei mangelhafter
            Nachbesserung sind wir nur zur einmaliger Wiederholung verpflichtet. Kommen
            wir trotz Mahnung und angemessener Fristsetzung schuldhaft dieser Pflicht nicht
            nach oder ist die Nachbesserung für uns unmöglich oder unzumutbar, so kann der
            Auftraggeber Minderung, jedoch höchstens zu einem Fünftell des bestätigten
            Auftragwertes oder Wandlung verlangen.
        </p>
        <p>
            6.3. Für Fremderzeugnisse oder Fremdleistungen beschränkt sich die Gewährleistung
            auf die Abtretung der uns gegen die Dritten zustehenden Gewähleistungs-
            ansprüche.
        </p>
        <p>
            6.4. Weitere Ansprüche, wie auch eine Haftung für Mangelfolgeschäden sind ausge-
            schlossen, sofern uns nicht Vorsatz oder grobe Fahrlässigkeit zur Last fallen.
        </p>
        <h3>7. Haftung</h3>
        <p>
            7.1. Unsere Haftung - gleich aus welchem Rechtsgrunde - ist beschränkt auf vor-
           sätzliche oder grob fahrlässige Handlungen. Auf jeden Fall haften wir nur für
           unmittelbare Schäden. Eine Haftung für entgangenen Gewinn ist ausgeschlossen.
           Die Haftung ist auf den Zeitwert des Containers beschränkt.
        </p>
        <p>
            7.2. Von Ansprüchen Dritter hat uns der Auftraggeber freizuhalten.
        </p>
        <p>
            7.3. Werden uns Gegenstände in Gewahrsam gegeben, so erfolgt das auf Gefahr des
            Auftraggebers. 
        </p>
        <p>
            7.4. Aufgrund vorstehender Haftungsausschlüsse ist der Auftraggeber verpflichtet, für
           die entsprechenden Versicherungen selbst zu sorgen.
        </p>
        <p>
            7.5. Bei Schäden aus der Bewegung von Containern/Wechselbehältern gelten die
          Höchstgrenzen der EVO.
        </p>
        <h3>8. Verjährung</h3>
        <p>
            8.1. Sämtliche Ansprüche des Auftraggebers verjähren in 2 Monaten nach der 
           Übergabe bzw. Lieferung.
        </p>
        <h3>9. Sonstiges</h3>
        <p>
            9.1. Vergeben wir den Auftrag weiter an einen Dritten, so gelten diese Bedingungen im 
           Verhältnis zum Auftraggeber auch zu Gunsten dieses Dritten
        </p>
        <p>
            9.2. Eigentum und Urheberrecht an unseren für den Auftraggeber gefertigten Unterlagen
           sind uns auf Verlange zurückzugeben und dürfen Dritten ohne unserer Einver-
           ständnis nicht zugänglich gemacht werden.
        </p>
        <h3>10. Schlußbestimmungen</h3>
        <p>
            10.1. Änderungen, Ergänzungen und Aufhebungen dieser Allgemeinen Geschäfts-
              bedingungen bedürfen der Schriftform.
        </p>
        <p>
            10.2. Sollten Teile dieser Allgemeinen Geschäftsbedingungen unwirksam seine oder
             unwirksam werden, so wird die Wirksamkeit der übrigen Bedingungen nicht
             berührt. Soweit eine Unvereinbarkeit mit zwingenden gesetzlichen Vorschriften
             gegeben ist, sind diese Bedingungen in dem gesetzlich zulässigen Umfang
             sinngemäß anzuwenden.
        </p>
        <p>
            10.3. Es gilt das Recht der Bundesrepublik Deutschland. Erfüllungsort für beide Teile
             und Gerichtsstand ist der Sitz unserer Gesellschaft.     
        </p>
    </div>
    
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>