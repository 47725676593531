import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DatenschutzComponent } from './datenschutz/datenschutz.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { AgbcomponentComponent } from './agbcomponent/agbcomponent.component';

const routes: Routes =[
  { path: '', component:HomeComponent},
  { path: 'datapolicy', component: DatenschutzComponent},
  { path: 'impressum', component: ImpressumComponent},
  { path: 'agb', component: AgbcomponentComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
