<div class="depo">
    <h1>
        Unser Containerdepot bietet folgende Dienstleistungen an
    </h1>
    <div class="features">
        <div class="square left">
            <div class="line"></div>
            <h3>01</h3>
            <p>Einlagerung von Leer- und Vollcontainern</p>
        </div>
        <div class="square">
            <h3>02</h3>
            <p>Wartung und Reparatur von Kühlcontainern/ Kühlaggregaten</p>
        </div>
        <div class="square">
            <h3>03</h3>
            <p>Auswärtiger Wartungs- und Reparaturdienst von Kühlcontainertechnik durch hauseigene Kühlmechaniker</p>
        </div>
        <div class="square">
            <h3>04</h3>
            <p>Allgemeine Reparatur vom Containergehäuse</p>
        </div>
        <div class="line"></div>
    <br/>
        <div class="square left">
            <div class="line"></div>
            <h3>05</h3>
            <p>Reinigung und Lackierung von Containern</p>
        </div>
        <div class="square">
            <h3>06</h3>
            <p>Reparatur von GenSet Dieselgeneratoren</p>
        </div>
        <div class="square">
            <h3>07</h3>
            <p>Neutralisierung und Anbringen von neuen Präfixen sowie Verkaufslogos des Containereigentümers</p>
        </div>
        <div class="square">
            <h3>08</h3>
            <p>Umladen von Fracht, Palettenware, Stückgut, zwischen zwei Containern</p>
        </div>
        <div class="line"></div>
    </div>
</div>
