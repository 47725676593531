import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-secondoverlay',
  templateUrl: './secondoverlay.component.html',
  styleUrls: ['./secondoverlay.component.css']
})
export class SecondoverlayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  closeNav2() {
    if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      document.getElementById("overlayimage2").style.width = "0%";
      document.getElementById("overlayimage2").style.height = "0px";
    }else{
      document.getElementById("overlayimage2").style.width = "0%";
      document.getElementById("overlayimage2").style.height = "100vh";
    }
    
    // document.getElementById("overlayimage2").style.display = "none";
    document.getElementById("overlaytext2").style.display = "none";    
    document.getElementById("myNav2").style.width = "0%";

  }
}
